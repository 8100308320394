define("labs-community-portal/templates/components/197a-plans", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QWzxp5Tj",
    "block": "[[[10,\"ul\"],[14,0,\"no-bullet\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[33,3]],null]],null]],null],null,[[[1,\"  \"],[10,\"li\"],[14,0,\"list-item-padded\"],[12],[1,\"\\n\"],[41,[30,1,[\"url\"]],[[[1,\"      \"],[10,3],[15,6,[29,[[30,1,[\"url\"]]]]],[12],[8,[39,5],null,[[\"@icon\"],[\"external-link-alt\"]],null],[1,\" \"],[10,\"strong\"],[12],[1,[30,1,[\"name\"]]],[13],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"strong\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,\"small\"],[14,0,\"list--link-meta\"],[12],[1,\"Sponsored by \"],[1,[30,1,[\"sponsor\"]]],[1,\" | Status: \"],[1,[30,1,[\"currentsta\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"],[18,2,null],[1,\"\\n\"]],[\"plan\",\"&default\"],false,[\"each\",\"-track-array\",\"await\",\"plans\",\"if\",\"fa-icon\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/197a-plans.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});