define("labs-community-portal/components/zap-list", ["exports", "@ember/object", "@ember/component", "fetch", "labs-community-portal/config/environment"], function (_exports, _object, _component, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line
  var _default = _component.default.extend({
    district: null,
    projects: (0, _object.computed)('district', function () {
      try {
        const zapAcronym = this.get('district.zapAcronym');
        /* eslint-disable-next-line no-unused-vars */
        const zapApi = _environment.default.ZAP_API;
        const URL = `${zapApi}/projects?community-districts[]=${zapAcronym}`;
        return (0, _fetch.default)(URL).then(res => res.json()).then(res => {
          // get the data object, return object with arrays of projects grouped by dcp_publicstatus_simp
          const projects = res.data;
          if (projects) {
            projects.forEach(project => {
              if (project.attributes.applicants) {
                const applicant = project.attributes.applicants.split(';')[0];
                project.attributes.applicant = applicant; // eslint-disable-line
              } else {
                project.attributes.applicant = 'Unknown Applicant';
              }
            });
          }
          return {
            filed: projects.filter(d => d.attributes['dcp-publicstatus'] === 'Filed'),
            inPublicReview: projects.filter(d => d.attributes['dcp-publicstatus'] === 'In Public Review')
          };
        });
      } catch (e) {
        console.log('error in the computed property of projects on zap-list.js', e); // eslint-disable-line
        throw e;
      }
    })
  });
  _exports.default = _default;
});