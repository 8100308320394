define("labs-community-portal/helpers/add-http", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addHttp = addHttp;
  _exports.default = void 0;
  function addHttp(params) {
    let [url] = params;
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = `http://${url}`;
    }
    return url;
  }
  var _default = (0, _helper.helper)(addHttp);
  _exports.default = _default;
});