define("labs-community-portal/templates/components/header-toggle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rt+dHWeW",
    "block": "[[[10,\"header\"],[14,\"role\",\"banner\"],[14,0,\"site-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"grid-x grid-padding-x\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"branding cell shrink large-auto\"],[12],[1,\"\\n      \"],[10,3],[14,0,\"dcp-link\"],[14,6,\"http://www1.nyc.gov/site/planning/index.page\"],[12],[10,\"img\"],[14,0,\"dcp-logo\"],[14,\"src\",\"https://raw.githubusercontent.com/NYCPlanning/logo/master/dcp_logo_772.png\"],[14,\"alt\",\"NYC Planning\"],[12],[13],[13],[1,\"\\n      \"],[6,[39,0],null,[[\"classNames\",\"route\"],[\"site-name\",\"index\"]],[[\"default\"],[[[[1,\"Community District Profiles\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"cell auto hide-for-large text-right\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"responsive-nav-toggler hide-for-print\"],[24,\"data-toggle\",\"responsive-menu\"],[4,[38,1],[[30,0],[28,[37,2],[[33,3]],null],[28,[37,4],[[33,3]],null]],null],[12],[1,\"Menu\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,1,\"responsive-menu\"],[15,0,[29,[\"cell large-shrink \",[52,[33,3],\"show-for-large\"],\" hide-for-print\"]]],[14,\"data-toggler\",\".show-for-large\"],[12],[1,\"\\n      \"],[10,\"nav\"],[14,\"role\",\"navigation\"],[12],[1,\"\\n        \"],[10,\"ul\"],[14,0,\"vertical large-horizontal menu\"],[12],[1,\"\\n          \"],[10,\"li\"],[12],[6,[39,0],null,[[\"route\"],[\"about\"]],[[\"default\"],[[[[1,\"About\"]],[]]]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"link-to\",\"action\",\"mut\",\"closed\",\"not\",\"if\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/header-toggle.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});