define("labs-community-portal/helpers/acs-puma-cd-tooltip", ["exports", "@ember/component/helper", "labs-community-portal/tooltips/tooltip-text"], function (_exports, _helper, _tooltipText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTooltip = buildTooltip;
  _exports.default = void 0;
  function buildTooltip(data) {
    const {
      puma,
      cd_short_title,
      shared_puma,
      shared_puma_cd
    } = data[0];
    const pumaTooltip = _tooltipText.default.acs.puma(puma);
    const cdApproximation = _tooltipText.default.acs.cdApproximation(cd_short_title);
    const cdApproximationShared = _tooltipText.default.acs.cdApproximationShared(cd_short_title, shared_puma_cd);
    const concatenatedCdApproximation = shared_puma ? cdApproximationShared : cdApproximation;
    return pumaTooltip.concat(concatenatedCdApproximation);
  }
  var _default = (0, _helper.helper)(buildTooltip);
  _exports.default = _default;
});