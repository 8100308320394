define("labs-community-portal/templates/components/waterfront-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XGubVlc3",
    "block": "[[[6,[39,0],null,[[\"id\",\"initOptions\",\"mapLoaded\"],[\"resiliency-map\",[33,1],[28,[37,2],[[30,0],\"handleMapLoad\"],null]]],[[\"default\"],[[[[44,[[28,[37,4],[[33,5]],null]],[[[41,[30,2],[[[1,\"\\n\"],[6,[30,1,[\"source\"]],null,[[\"sourceId\",\"source\"],[\"floodzones\",[30,2]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[30,3,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,7],\"building\"]]]],[1,\"\\n      \"],[1,[28,[30,3,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,8],\"building\"]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n    \"],[1,[28,[30,1,[\"call\"]],[\"fitBounds\",[33,9,[\"bounds\"]],[33,10]],null]],[1,\"\\n\\n\"],[41,[33,9,[\"currentlySelected\"]],[[[6,[30,1,[\"source\"]],null,[[\"sourceId\",\"source\"],[\"currentlySelected\",[33,11]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[30,4,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,12],\"place_other\"]]]],[1,\"\\n\"]],[4]]]]]],[]],null]],[]],null]],[2]]],[1,\"\\n\"]],[1]]]]],[18,5,null],[1,\"\\n\"]],[\"map\",\"vSource\",\"source\",\"source\",\"&default\"],false,[\"mapbox-gl\",\"initOptions\",\"action\",\"let\",\"await\",\"vectorSource\",\"if\",\"floodplain500Layer\",\"floodplain100Layer\",\"mapState\",\"fitBoundsOptions\",\"cdSelectedSource\",\"cdSelectedLayer\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/waterfront-map.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});