define("labs-community-portal/components/key-indicator", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    borocd: '',
    column: '',
    data: [],
    unit: '',
    numeral_format: '0.0',
    sortedData: (0, _object.computed)('data', 'borocd', function () {
      const borocd = this.get('borocd');
      return this.get('data').then(data => data.sortBy(`${this.get('column')}`).reverse().map(d => {
        d.is_selected = borocd === d.borocd;
        return d;
      }));
    })
  });
  _exports.default = _default;
});