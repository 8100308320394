define("labs-community-portal/components/197a-plans", ["exports", "@ember/object", "@ember/component", "labs-community-portal/utils/carto"], function (_exports, _object, _component, _carto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _component.default.extend({
    district: null,
    plans: (0, _object.computed)('district', function () {
      const borocd = this.get('district.borocd');
      const SQL = `SELECT * FROM cdprofiles_197a_plans WHERE cd LIKE '%25${borocd}%25'`;
      return _carto.default.SQL(SQL, 'json');
    })
  });
  _exports.default = _default;
});