define("labs-community-portal/utils/is-cd-layer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isCdLayer;
  function isCdLayer(source) {
    return source === 'cds' || source === 'currentlySelected';
  }
});