define("labs-community-portal/templates/not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "D9La175Y",
    "block": "[[[10,0],[14,0,\"grid-x grid-padding-x\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cell text-center\"],[14,5,\"padding-top:5vh;padding-bottom:20vh;\"],[12],[1,\"\\n    \"],[10,\"h1\"],[12],[1,\"Sorry.\"],[10,\"br\"],[12],[13],[10,\"small\"],[12],[1,\"We can't find what you're looking for.\"],[13],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Please double-check the URL or choose a Community District.\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Still can't find what you're looking for? Please \"],[10,3],[14,6,\"https://github.com/NYCPlanning/labs-community-portal/issues\"],[12],[1,\"file an issue\"],[13],[1,\". \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "labs-community-portal/templates/not-found.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});