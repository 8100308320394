define("labs-community-portal/components/mapbox-gl-source", ["exports", "@ember/runloop", "@ember/component", "@ember/object"], function (_exports, _runloop, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // extends ember-mapbox-gl's source component to have an API that is more like mapbox.addSource()
  // eslint-disable-line
  var _default = _component.default.extend({
    map: null,
    id: '',
    source: {},
    init() {
      this._super();
      const {
        sourceId,
        source
      } = (0, _object.getProperties)(this, 'sourceId', 'source');
      this.map.addSource(sourceId, source);
    },
    didUpdateAttrs() {
      this._super(...arguments); // eslint-disable-line

      const {
        sourceId,
        source
      } = (0, _object.getProperties)(this, 'sourceId', 'source');
      this.map.getSource(sourceId).setData(source.data);
    },
    willDestroy() {
      this._super(...arguments); // eslint-disable-line
      const {
        sourceId
      } = this;
      (0, _runloop.scheduleOnce)('afterRender', this.map, this.map.removeSource, sourceId);
    }
  });
  _exports.default = _default;
});