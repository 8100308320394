define("labs-community-portal/models/address", ["exports", "@ember/object/computed", "@ember/object", "ember-data", "labs-community-portal/utils/to-geojson", "@turf/inside"], function (_exports, _computed, _object, _emberData, _toGeojson, _inside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line
  // eslint-disable-line
  // eslint-disable-line
  var _default = _emberData.default.Model.extend({
    district: (0, _object.computed)('geometry', function () {
      const districts = this.store.peekAll('district');
      const point = this.get('geometry');
      return districts.find(dist => (0, _inside.default)(point, dist.get('geometry'))) || _object.default.create();
    }),
    borocd: (0, _object.computed)('district', function () {
      const district = this.get('district');
      return district.get('borocd');
    }),
    boro: (0, _object.computed)('district', function () {
      const district = this.get('district');
      return district.get('boro');
    }),
    cd: (0, _object.computed)('district', function () {
      const district = this.get('district');
      return district.get('cd');
    }),
    name: (0, _computed.alias)('label'),
    neighborhoods: (0, _computed.alias)('label'),
    label: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    geometry: _emberData.default.attr(),
    locality: _emberData.default.attr('string')
  });
  _exports.default = _default;
});