define("labs-community-portal/templates/components/hover-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jL88Fgck",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"map-popup callout\"],[15,5,[36,2]],[12],[1,\"\\n    \"],[1,[34,3]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"mouse\",\"style\",\"text\"]]",
    "moduleName": "labs-community-portal/templates/components/hover-tooltip.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});