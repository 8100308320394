define("labs-community-portal/serializers/district", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONSerializer.extend({
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      return this._super(store, primaryModelClass, payload.features.map(feature => {
        const thisFeature = feature;
        thisFeature.properties.geometry = feature.geometry;
        thisFeature.properties.geometryType = payload.type;
        return thisFeature.properties;
      }), id, requestType);
    }
  });
  _exports.default = _default;
});