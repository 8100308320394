define("labs-community-portal/templates/components/site-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Pkwy9zev",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"site-message warning\"],[12],[1,\"\\n\\n    \"],[10,0],[14,0,\"grid-x grid-margin-x align-middle\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"cell text-center medium-text-left medium-shrink\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,0,\"yellow-dark site-message-warning\"]],[[\"@icon\",\"@size\"],[\"exclamation-triangle\",\"2x\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"cell auto\"],[12],[1,\"\\n        As we work to update and improve our public digital tools, please be advised that the Community District Profiles tools is currently populated with 2010 Census Data. Content and information that does not rely on census data remains up to date. For more updated census data, please visit our \"],[10,3],[14,\"target\",\"_blank\"],[14,6,\"https://popfactfinder.planning.nyc.gov/\"],[12],[1,\"NYC Population Fact Finder (https://popfactfinder.planning.nyc.gov/)\"],[13],[1,\".\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[33,3],[[[1,\"      \"],[10,\"button\"],[14,0,\"close-button\"],[14,\"aria-label\",\"Dismiss message\"],[14,\"data-close\",\"\"],[15,\"onclick\",[28,[37,4],[[30,0],\"handleSiteMessageToggle\"],null]],[14,4,\"button\"],[12],[1,\"\\n        \"],[10,1],[14,\"aria-hidden\",\"true\"],[12],[1,\"×\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"]],[]],null]],[],false,[\"if\",\"open\",\"fa-icon\",\"dismissible\",\"action\"]]",
    "moduleName": "labs-community-portal/templates/components/site-message.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});