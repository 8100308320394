define("labs-community-portal/mixins/set-map-bounds", ["exports", "@ember/service", "@ember/object/mixin", "@turf/bbox", "labs-community-portal/utils/to-geojson"], function (_exports, _service, _mixin, _bbox, _toGeojson) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _mixin.default.create({
    mapState: (0, _service.inject)(),
    afterModel(districts) {
      this._super(...arguments); // eslint-disable-line
      const mapState = this.get('mapState');
      const geojsonDistricts = (0, _toGeojson.default)(districts);
      mapState.set('bounds', (0, _bbox.default)(geojsonDistricts));
    }
  });
  _exports.default = _default;
});