define("labs-community-portal/components/needs-statements", ["exports", "@ember/object", "@ember/component", "fetch"], function (_exports, _object, _component, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const statementsPath = 'https://api.github.com/repos/NYCPlanning/labs-cd-needs-statements/git/trees/master?recursive=1';
  const download = 'https://github.com/NYCPlanning/labs-cd-needs-statements/raw/master/';
  function stripDirectory(string) {
    const loc = string.lastIndexOf('/');
    return string.substring(loc + 1, string.length);
  }
  var _default = _component.default.extend({
    district: null,
    downloadPath: download,
    allStatements: (0, _object.computed)('district', function () {
      return (0, _fetch.default)(statementsPath).then(d => d.json());
    }),
    availableYears: (0, _object.computed)('district', function () {
      const district = this.get('district');
      return this.get('allStatements').then(statements => statements.tree.filter(statement => statement.type === 'blob').filter(statement => statement.path.indexOf(district.get('borocdAcronym')) !== -1).map(statement => {
        statement.name = stripDirectory(statement.path);
        return statement;
      }).reverse());
    }),
    showAll: false,
    truncatedYears: (0, _object.computed)('district', 'showAll', function () {
      return this.get('availableYears').then(years => {
        if (this.get('showAll')) {
          return years;
        }
        return years.slice(0, 3);
      });
    }),
    actions: {
      toggleList() {
        this.toggleProperty('showAll');
      }
    }
  });
  _exports.default = _default;
});