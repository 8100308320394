define("labs-community-portal/components/zoning-chart", ["exports", "@ember/object", "@ember/component", "ember-resize/mixins/resize-aware", "d3-array", "d3-scale", "d3-selection", "labs-community-portal/utils/githubraw", "d3-transition"], function (_exports, _object, _component, _resizeAware, _d3Array, _d3Scale, _d3Selection, _githubraw, _d3Transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  // eslint-disable-line

  const colors = function (zonedist) {
    if (zonedist === 'R') return '#F3F88F';
    if (zonedist === 'M') return '#F8B7FB';
    if (zonedist === 'C') return '#FF8E8E';
    if (zonedist === 'P') return '#B5E6B9';
    return '#BCBCBB';
  };
  const descriptions = function (zonedist) {
    if (zonedist === 'R') return 'Residence';
    if (zonedist === 'M') return 'Manufacturing';
    if (zonedist === 'C') return 'Commercial';
    if (zonedist === 'P') return 'Park';
    if (zonedist === 'B') return 'Battery Park City';
    return 'Other Zones';
  };
  const ZoningChart = _component.default.extend(_resizeAware.default, {
    classNameBindings: ['loading'],
    classNames: ['land-use-chart'],
    resizeWidthSensitive: true,
    resizeHeightSensitive: true,
    loading: false,
    height: 400,
    borocd: '',
    data: (0, _object.computed)('sql', 'borocd', function () {
      const borocd = this.get('borocd');
      return (0, _githubraw.default)('zoning', borocd);
    }),
    didRender() {
      this.createChart();
    },
    debouncedDidResize(width) {
      this.set('width', width);
      this.updateChart();
    },
    createChart() {
      let svg = this.get('svg');
      if (!svg) {
        const el = this.$();
        svg = (0, _d3Selection.select)(el.get(0)).append('svg').attr('class', 'chart');
      }
      this.set('svg', svg);
      this.updateChart();
    },
    updateChart() {
      const svg = this.get('svg');
      const data = this.get('data');
      const el = this.$();
      const elWidth = el.width();
      const margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };
      const height = this.get('height') - margin.top - margin.bottom;
      const width = elWidth - margin.left - margin.right;
      svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
      data.then(rawData => {
        const y = (0, _d3Scale.scaleBand)().domain(rawData.map(d => d.zonedist)).range([0, height]).paddingOuter(0).paddingInner(0.2);
        const x = (0, _d3Scale.scaleLinear)().domain([0, (0, _d3Array.max)(rawData, d => d.percent)]).range([0, width]);
        const bars = svg.selectAll('.bar').data(rawData, d => d.zonedist);
        bars.enter().append('rect').attr('class', 'bar').attr('fill', d => colors(d.zonedist)).attr('x', 0).attr('height', y.bandwidth() - 15).attr('rx', 2).attr('ry', 2).attr('y', d => y(d.zonedist)).attr('width', d => x(d.percent));
        bars.transition().duration(300).attr('height', y.bandwidth() - 15).attr('y', d => y(d.zonedist)).attr('width', d => x(d.percent));
        bars.exit().remove();
        const labels = svg.selectAll('text').data(rawData, d => d.zonedist);
        labels.enter().append('text').attr('class', 'label').attr('text-anchor', 'left').attr('alignment-baseline', 'top').attr('x', 0).attr('y', d => y(d.zonedist) + y.bandwidth() + -3).text(d => {
          const description = descriptions(d.zonedist);
          return `${description} | ${(d.percent * 100).toFixed(2)}%`;
        });
        labels.transition().duration(300).attr('y', d => y(d.zonedist) + y.bandwidth() + -3).text(d => {
          const description = descriptions(d.zonedist);
          return `${description} | ${(d.percent * 100).toFixed(2)}%`;
        });
        labels.exit().remove();
      });
    }
  });
  var _default = ZoningChart;
  _exports.default = _default;
});