define("labs-community-portal/helpers/census-floodplain-tooltip", ["exports", "@ember/component/helper", "labs-community-portal/tooltips/tooltip-text"], function (_exports, _helper, _tooltipText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTooltip = buildTooltip;
  _exports.default = void 0;
  function buildTooltip(data) {
    const {
      cd_short_title
    } = data[0];
    return _tooltipText.default.census.censusFloodplain(cd_short_title);
  }
  var _default = (0, _helper.helper)(buildTooltip);
  _exports.default = _default;
});