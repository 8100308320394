define("labs-community-portal/routes/about", ["exports", "@ember/runloop", "@ember/service", "@ember/routing/route", "labs-community-portal/mixins/set-map-bounds", "labs-community-portal/mixins/scroll-to-top"], function (_exports, _runloop, _service, _route, _setMapBounds, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _route.default.extend(_scrollToTop.default, _setMapBounds.default, {
    scroller: (0, _service.inject)(),
    model() {
      return this.modelFor('application');
    },
    actions: {
      didTransition() {
        const scroller = this.get('scroller');
        const {
          section
        } = this.paramsFor('about');
        if (section) {
          (0, _runloop.next)(this, () => {
            scroller.scrollVertical(`#${section}`, {
              // offset: -115,
            });
          });
        }
      }
    }
  });
  _exports.default = _default;
});