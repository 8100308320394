define("labs-community-portal/templates/components/studies-and-plans", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rUwkIwUP",
    "block": "[[[10,\"ul\"],[14,0,\"no-bullet\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[33,3]],null]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[14,0,\"list-item-padded\"],[12],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,1,[\"url\"]]]]],[12],[8,[39,4],null,[[\"@icon\"],[\"external-link-alt\"]],null],[1,\" \"],[10,\"strong\"],[12],[1,[30,1,[\"name\"]]],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,\"li\"],[14,0,\"list-item-padded\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"text-center\"],[14,5,\"padding: 60px 0;\"],[12],[1,\"No recent or ongoing studies.\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[10,3],[14,0,\"button small hollow expanded\"],[14,\"target\",\"_blank\"],[14,6,\"https://www1.nyc.gov/site/planning/plans/city-wide.page\"],[12],[10,\"strong\"],[12],[1,\"View all City Planning Studies\"],[13],[13],[1,\"\\n\"],[10,3],[14,0,\"button small hollow expanded\"],[14,\"target\",\"_blank\"],[14,6,\"https://www.nycedc.com/projects/all\"],[12],[10,\"strong\"],[12],[1,\"View all NYC Economic Development Corporation Projects\"],[13],[13],[1,\"\\n\"],[10,3],[14,0,\"button small hollow expanded no-margin\"],[14,\"target\",\"_blank\"],[14,6,\"https://www1.nyc.gov/site/hpd/community/neighborhood-plans.page\"],[12],[10,\"strong\"],[12],[1,\"View NYC Housing Preservation & Development Planning Activity\"],[13],[13],[1,\"\\n\\n\"],[18,2,null],[1,\"\\n\"]],[\"study\",\"&default\"],false,[\"each\",\"-track-array\",\"await\",\"studies\",\"fa-icon\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/studies-and-plans.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});