define("labs-community-portal/utils/githubraw", ["exports", "fetch"], function (_exports, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = githubraw;
  // eslint-disable-line

  function githubraw(id, borocd) {
    const URL = `https://raw.githubusercontent.com/NYCPlanning/labs-community-data/master/data/${id}/${borocd}.json`;
    return (0, _fetch.default)(URL).then(data => data.json());
  }
});