define("labs-community-portal/routes/index", ["exports", "@ember/service", "@ember/routing/route", "labs-community-portal/utils/to-geojson", "@turf/bbox", "labs-community-portal/mixins/scroll-to-top"], function (_exports, _service, _route, _toGeojson, _bbox, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line
  var _default = _route.default.extend(_scrollToTop.default, {
    mapState: (0, _service.inject)(),
    model() {
      return this.modelFor('application');
    },
    afterModel(districts) {
      const mapState = this.get('mapState');
      const geojsonDistricts = (0, _toGeojson.default)(districts);
      mapState.set('bounds', (0, _bbox.default)(geojsonDistricts));
    },
    actions: {
      didTransition() {
        const mapState = this.get('mapState');
        mapState.set('currentlySelected', null);
      }
    }
  });
  _exports.default = _default;
});