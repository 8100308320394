define("labs-community-portal/components/land-use-chart", ["exports", "@ember/object", "@ember/component", "ember-resize/mixins/resize-aware", "d3-array", "d3-scale", "d3-selection", "d3-transition", "labs-community-portal/utils/carto", "labs-community-portal/utils/land-use-lookup"], function (_exports, _object, _component, _resizeAware, _d3Array, _d3Scale, _d3Selection, _d3Transition, _carto, _landUseLookup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  // eslint-disable-line

  const LandUseChart = _component.default.extend(_resizeAware.default, {
    classNameBindings: ['loading'],
    classNames: ['land-use-chart'],
    resizeWidthSensitive: true,
    resizeHeightSensitive: true,
    loading: false,
    borocd: '',
    sql: (0, _object.computed)('borocd', function sql() {
      const borocd = this.get('borocd');
      const SQL = `
    WITH lots AS (
      SELECT
        a.the_geom,
        CASE
          WHEN c.description IS NOT NULL THEN c.description
          ELSE 'Other'
        END as landuse_desc,
        c.code as landuse,
        lotarea
      FROM mappluto a
      LEFT JOIN support_landuse_lookup c
            ON a.landuse::integer = c.code
      WHERE a.cd = '${borocd}'
      AND a.address != 'BODY OF WATER'
    ),
    totalsf AS (
      SELECT sum(lotarea) as total
      FROM lots
    )
    SELECT
      count(landuse_desc),
      landuse,
      landuse_desc
    FROM lots, totalsf
    GROUP BY landuse, landuse_desc, totalsf.total
    `;
      return SQL;
    }),
    communityProfilesSql: (0, _object.computed)('borocd', function sql() {
      const borocd = this.get('borocd');
      const SQL = `
    SELECT
      borocd,
      pct_lot_area_commercial_office,
      pct_lot_area_industrial_manufacturing,
      pct_lot_area_mixed_use,
      pct_lot_area_open_space,
      pct_lot_area_other_no_data,
      pct_lot_area_parking,
      pct_lot_area_public_facility_institution,
      pct_lot_area_res_1_2_family_bldg,
      pct_lot_area_res_multifamily_elevator,
      pct_lot_area_res_multifamily_walkup,
      pct_lot_area_transportation_utility,
      pct_lot_area_vacant
    FROM community_district_profiles_v202402
    WHERE borocd = '${borocd}'
    `;
      return SQL;
    }),
    data: (0, _object.computed)('sql', 'borocd', function () {
      const sql = this.get('sql');
      return _carto.default.SQL(sql);
    }),
    communityProfilesData: (0, _object.computed)('communityProfilesSql', 'borocd', function () {
      const sql = this.get('communityProfilesSql');
      return _carto.default.SQL(sql);
    }),
    didRender() {
      this.createChart();
    },
    debouncedDidResize(width) {
      this.set('width', width);
      this.updateChart();
    },
    createChart: function createChart() {
      let svg = this.get('svg');
      if (!svg) {
        const el = this.$();
        svg = (0, _d3Selection.select)(el.get(0)).append('svg').attr('class', 'chart');
      }
      this.set('svg', svg);
      this.updateChart();
    },
    updateChart: function updateChart() {
      const svg = this.get('svg');
      const data = this.get('data');
      const el = this.$();
      const elWidth = el.width();
      const margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };
      const height = 400 - margin.top - margin.bottom;
      const width = elWidth - margin.left - margin.right;
      svg.attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);
      data.then(rawData => {
        this.get('communityProfilesData').then(communityProfilesData => {
          // NAME of lot area percent field on community_district_profiles_v202402 dataset
          function landUsePercentField(plutoData) {
            return (0, _landUseLookup.default)(plutoData.landuse).community_profiles_percent_field;
          }

          // VALUE of lot area percent field pulled from community_district_profiles_v202402 dataset
          function lotAreaPercent(plutoData) {
            const percentField = landUsePercentField(plutoData);
            const percentValue = communityProfilesData[0][percentField];
            return percentValue / 100;
          }

          // sort rawData by lot area percent field value
          rawData.sort(function (a, b) {
            return communityProfilesData[0][landUsePercentField(b)] - communityProfilesData[0][landUsePercentField(a)];
          });
          const y = (0, _d3Scale.scaleBand)().domain(rawData.map(d => d.landuse_desc)).range([0, height]).paddingOuter(0).paddingInner(0.2);
          const x = (0, _d3Scale.scaleLinear)().domain([0, (0, _d3Array.max)(rawData, d => lotAreaPercent(d))]).range([0, width]);
          const bars = svg.selectAll('.bar').data(rawData, d => d.landuse);
          bars.enter().append('rect').attr('class', 'bar').attr('fill', d => (0, _landUseLookup.default)(d.landuse).color).attr('x', 0).attr('height', y.bandwidth() - 15).attr('rx', 2).attr('ry', 2).attr('y', d => y(d.landuse_desc)).attr('width', d => x(lotAreaPercent(d)));
          bars.transition().duration(300).attr('height', y.bandwidth() - 15).attr('y', d => y(d.landuse_desc)).attr('width', d => x(lotAreaPercent(d)));
          bars.exit().remove();
          const labels = svg.selectAll('text').data(rawData, d => d.landuse);
          labels.enter().append('text').attr('class', 'label').attr('text-anchor', 'left').attr('alignment-baseline', 'top').attr('x', 0).attr('y', d => y(d.landuse_desc) + y.bandwidth() + -3).text(d => `${d.landuse_desc} | ${(lotAreaPercent(d) * 100).toFixed(2)}%`);
          labels.transition().duration(300).attr('y', d => y(d.landuse_desc) + y.bandwidth() + -3).text(d => `${d.landuse_desc} | ${(lotAreaPercent(d) * 100).toFixed(2)}%`);
          labels.exit().remove();
        });
      });
    }
  });
  var _default = LandUseChart;
  _exports.default = _default;
});