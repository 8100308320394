define("labs-community-portal/templates/components/land-use-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vZXVSMBI",
    "block": "[[[6,[39,0],null,[[\"id\",\"initOptions\",\"mapLoaded\"],[\"lu-map\",[33,1],[28,[37,2],[[30,0],\"handleMapLoad\"],null]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,4],[[33,5]],null]],[[[41,[30,2],[[[6,[30,1,[\"source\"]],null,[[\"sourceId\",\"source\"],[\"pluto-landuse\",[30,2]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[30,3,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,7],\"place_other\"]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"],[41,[33,8,[\"currentlySelected\"]],[[[6,[30,1,[\"source\"]],null,[[\"sourceId\",\"source\"],[\"currentlySelected\",[33,9]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[30,4,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,10],\"place_other\"]]]],[1,\"\\n\"]],[4]]]]]],[]],null]],[]],null]],[2]]],[1,\"\\n\\n  \"],[1,[28,[30,1,[\"call\"]],[\"fitBounds\",[33,8,[\"bounds\"]],[33,11]],null]],[1,\"\\n  \"],[1,[28,[30,1,[\"on\"]],[\"mousemove\",[28,[37,2],[[30,0],\"handleMouseover\"],null]],null]],[1,\"\\n  \"],[1,[28,[30,1,[\"on\"]],[\"mouseout\",[28,[37,2],[[30,0],\"handleMouseleave\"],null]],null]],[1,\"\\n\\n  \"],[1,[28,[35,12],null,[[\"mouse\",\"text\"],[[33,13],[33,14]]]]],[1,\"\\n\"]],[1]]]]],[18,5,null],[1,\"\\n\"]],[\"map\",\"vSource\",\"source\",\"source\",\"&default\"],false,[\"mapbox-gl\",\"initOptions\",\"action\",\"let\",\"await\",\"vectorSource\",\"if\",\"vectorLayer\",\"mapState\",\"cdSelectedSource\",\"cdSelectedLayer\",\"fitBoundsOptions\",\"hover-tooltip\",\"mouseoverLocation\",\"tooltip-text\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/land-use-map.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});