define("labs-community-portal/components/floodplain-charts", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _component.default.extend({
    mode: '100-yr',
    buildingComparisonChartColumn: (0, _object.computed)('mode', function () {
      return this.mode === '100-yr' ? 'fp_100_bldg' : 'fp_500_bldg';
    }),
    resunitsComparisonChartColumn: (0, _object.computed)('mode', function () {
      return this.mode === '100-yr' ? 'fp_100_resunits' : 'fp_500_resunits';
    }),
    actions: {
      setMode(mode) {
        this.set('mode', mode);
      },
      sum(accum, curr) {
        return accum + curr;
      }
    }
  });
  _exports.default = _default;
});