define("labs-community-portal/components/hover-tooltip", ["exports", "@ember/string", "@ember/object", "@ember/component"], function (_exports, _string, _object, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    'tooltip-text': '',
    mouse: null,
    style: (0, _object.computed)('mouse', function () {
      const mouse = this.get('mouse');
      return (0, _string.htmlSafe)(`top: ${mouse.y}px; 
                                  left: ${mouse.x - 20}px;`);
    })
  });
  _exports.default = _default;
});