define("labs-community-portal/templates/components/building-type-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z2/4jJVA",
    "block": "[[[18,1,[[28,[37,1],null,[[\"data\"],[[33,2]]]]]],[1,\"\\n\\n\"],[1,[28,[35,3],null,[[\"data\",\"height\"],[[33,2],340]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"data\",\"horizontal-bar\"]]",
    "moduleName": "labs-community-portal/templates/components/building-type-chart.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});