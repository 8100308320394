define("labs-community-portal/templates/components/mapbox-gl-source", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4HvsuL5x",
    "block": "[[[18,1,[[28,[37,1],null,[[\"layer\"],[[50,\"mapbox-gl-layer\",0,null,[[\"map\"],[[33,3]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"map\"]]",
    "moduleName": "labs-community-portal/templates/components/mapbox-gl-source.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});