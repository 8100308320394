define("labs-community-portal/adapters/address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const mapzenSearchAPI = 'https://geosearch.planninglabs.nyc/v2/autocomplete?focus.point.lat=40.7259&focus.point.lon=-73.9805&limit=5&text=';
  var _default = _emberData.default.JSONAPIAdapter.extend({
    keyForAttribute(key) {
      return key;
    },
    buildURL(modelName, id, snapshot, requestType, query) {
      const url = `${mapzenSearchAPI}${query}`;
      return url;
    }
  });
  _exports.default = _default;
});