define("labs-community-portal/utils/to-geojson", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = toGeojson;
  function toGeojson(districts) {
    return {
      type: 'FeatureCollection',
      features: districts.map(district => ({
        geometry: district.get('geometry'),
        properties: district
      }))
    };
  }
});