define("labs-community-portal/helpers/acs-floodplain-tooltip", ["exports", "@ember/component/helper", "labs-community-portal/tooltips/tooltip-text"], function (_exports, _helper, _tooltipText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTooltip = buildTooltip;
  _exports.default = void 0;
  function buildTooltip(data) {
    const {
      puma,
      cd_short_title
    } = data[0];
    const acsFloodplain = _tooltipText.default.acs.acsFloodplain(puma);
    const cdApproximation = _tooltipText.default.acs.cdApproximation(cd_short_title);
    return acsFloodplain.concat(cdApproximation);
  }
  var _default = (0, _helper.helper)(buildTooltip);
  _exports.default = _default;
});