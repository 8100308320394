define("labs-community-portal/components/scroll-to", ["exports", "ember-scroll-to/components/scroll-to"], function (_exports, _scrollTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _scrollTo.default.extend({
    // ----- Events -----
    scroll: Em.on('click', function (evt) {
      // eslint-disable-line
      evt.stopPropagation();
      evt.preventDefault();
      this.get('scroller').scrollVertical(this.get('jQueryElement'), {
        duration: this.get('duration'),
        offset: this.get('offset'),
        easing: this.get('easing'),
        complete: () => Em.run(this, this.sendAction, 'afterScroll', this.get('href'), evt) // eslint-disable-line
      });
    })
  });
  _exports.default = _default;
});