define("labs-community-portal/routes/profile/index", ["exports", "@ember/runloop", "@ember/service", "@ember/routing/route"], function (_exports, _runloop, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  var _default = _route.default.extend({
    mapState: (0, _service.inject)(),
    scroller: (0, _service.inject)(),
    model() {
      return this.modelFor('profile');
    },
    afterModel(district) {
      const mapState = this.get('mapState');

      // seeing async issues - putting inside run loop to stagger
      (0, _runloop.next)(this, () => {
        mapState.set('currentlySelected', district);
      });
    },
    actions: {
      didTransition() {
        const scroller = this.get('scroller');
        const section = window.location.hash.substr(1);
        if (section) {
          (0, _runloop.next)(this, () => {
            scroller.scrollVertical(`#${section}`, {});
          });
        }
      },
      willTransition() {
        this.controllerFor('profile').set('section', '');
      },
      error() {
        return true;
      }
    }
  });
  _exports.default = _default;
});