define("labs-community-portal/adapters/district", ["exports", "ember-data", "labs-community-portal/utils/carto"], function (_exports, _emberData, _carto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SQL = `
  SELECT ST_Simplify(the_geom, 0.0001) AS the_geom, borocd %25 100 as cd,
    CASE
      WHEN LEFT(borocd::text, 1) = '1' THEN 'Manhattan'
      WHEN LEFT(borocd::text, 1) = '2' THEN 'Bronx'
      WHEN LEFT(borocd::text, 1) = '3' THEN 'Brooklyn'
      WHEN LEFT(borocd::text, 1) = '4' THEN 'Queens'
      WHEN LEFT(borocd::text, 1) = '5' THEN 'Staten Island'
    END as boro,
    borocd,
    cartodb_id as id
  FROM community_districts
  WHERE borocd %25 100 %3C 20
  ORDER BY boro, cd ASC
`;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    keyForAttribute(key) {
      return key;
    },
    buildURL() {
      return (0, _carto.buildSqlUrl)(SQL, 'geojson');
    }
  });
  _exports.default = _default;
});