define("labs-community-portal/helpers/numeral-format", ["exports", "@ember/component/helper", "numeral"], function (_exports, _helper, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numeralFormat = numeralFormat;
  function numeralFormat(params) {
    const [number, template] = params;
    return (0, _numeral.default)(number).format(template);
  }
  var _default = (0, _helper.helper)(numeralFormat);
  _exports.default = _default;
});