define("labs-community-portal/components/initialize-foundation", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    didRender() {
      $(this.element).foundation();
    }
  });
  _exports.default = _default;
});