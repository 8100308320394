define("labs-community-portal/templates/components/horizontal-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WM57lrgl",
    "block": "[[[41,[28,[37,1],[[33,2]],null],[[[1,\"  \"],[10,0],[14,0,\"spinner-wrapper loading\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"spinner\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"bounce1\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"bounce2\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"bounce3\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"is-pending\",\"data\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/horizontal-bar.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});