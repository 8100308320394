define("labs-community-portal/components/tooltip-on-element", ["exports", "labs-community-portal/config/environment", "ember-tooltips/components/tooltip-on-element"], function (_exports, _environment, _tooltipOnElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let _didUpdateTimeoutLength = _environment.default.environment === 'test' ? 0 : 1000;
  var _default = _tooltipOnElement.default.extend({
    _didUpdateTimeoutLength
  });
  _exports.default = _default;
});