define("labs-community-portal/utils/carto", ["exports", "fetch", "rsvp"], function (_exports, _fetch, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.buildSqlUrl = void 0;
  // eslint-disable-line
  // eslint-disable-line

  const cartoDomain = 'planninglabs.carto.com';
  const buildTemplate = (layergroupid, type) => {
    // eslint-disable-line
    return `https://${cartoDomain}/api/v1/map/${layergroupid}/{z}/{x}/{y}.${type}`;
  };
  const buildSqlUrl = function (cleanedQuery) {
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
    // eslint-disable-line
    return `https://${cartoDomain}/api/v2/sql?q=${cleanedQuery}&format=${type}`;
  };
  _exports.buildSqlUrl = buildSqlUrl;
  const carto = {
    SQL(query) {
      let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
      const cleanedQuery = query.replace('\n', '');
      const url = buildSqlUrl(cleanedQuery, type);
      return (0, _fetch.default)(url).then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Not found');
      }).then(d => {
        // eslint-disable-line
        return type === 'json' ? d.rows : d;
      });
    },
    getVectorTileTemplate(SQLArray) {
      const CartoCSS = '#layer { polygon-fill: #FFF; }';
      const layers = SQLArray.map(sql => ({
        type: 'mapnik',
        options: {
          cartocss_version: '2.1.1',
          cartocss: CartoCSS,
          sql
        }
      }));
      const params = {
        version: '1.3.0',
        layers
      };
      return new _rsvp.Promise((resolve, reject) => {
        (0, _fetch.default)(`https://${cartoDomain}/api/v1/map`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        }).catch(err => reject(err)).then(response => response.json()).then(json => {
          resolve(buildTemplate(json.layergroupid, 'mvt'));
        });
      });
    }
  };
  var _default = carto;
  _exports.default = _default;
});