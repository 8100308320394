define("labs-community-portal/components/land-use-map", ["exports", "@ember/service", "@ember/component", "mapbox-gl", "@ember/object", "labs-community-portal/utils/carto", "labs-community-portal/utils/land-use-lookup"], function (_exports, _service, _component, _mapboxGl, _object, _carto, _landUseLookup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  // eslint-disable-line

  const SQL = 'SELECT a.the_geom_webmercator, a.landuse, b.description, address FROM mappluto a LEFT JOIN support_landuse_lookup b ON a.landuse::integer = b.code';
  var _default = _component.default.extend({
    initOptions: {
      style: 'https://layers-api.planninglabs.nyc/v1/base/style.json',
      zoom: 9,
      minZoom: 13.01,
      center: [-74, 40.7071],
      scrollZoom: false
    },
    fitBoundsOptions: {
      linear: true,
      duration: 0
    },
    vectorSource: (0, _object.computed)('landuseTemplate', async function () {
      // eslint-disable-line
      return _carto.default.getVectorTileTemplate([SQL]).then(landuseTemplate => ({
        type: 'vector',
        tiles: [landuseTemplate]
      }));
    }),
    vectorLayer: {
      id: 'landuse-vector',
      type: 'fill',
      source: 'pluto-landuse',
      'source-layer': 'layer0',
      paint: {
        'fill-color': ['match', ['get', 'landuse'], '01', (0, _landUseLookup.default)(1).color, '02', (0, _landUseLookup.default)(2).color, '03', (0, _landUseLookup.default)(3).color, '04', (0, _landUseLookup.default)(4).color, '05', (0, _landUseLookup.default)(5).color, '06', (0, _landUseLookup.default)(6).color, '07', (0, _landUseLookup.default)(7).color, '08', (0, _landUseLookup.default)(8).color, '09', (0, _landUseLookup.default)(9).color, '10', (0, _landUseLookup.default)(10).color, '11', (0, _landUseLookup.default)(11).color, /* other */(0, _landUseLookup.default)(12).color],
        'fill-opacity': 1
      }
    },
    rasterLayer: {
      id: 'landuse-raster',
      type: 'raster',
      source: 'pluto-raster',
      maxzoom: 14
    },
    cdSelectedSource: (0, _object.computed)('mapState.currentlySelected.geometry', function () {
      return {
        type: 'geojson',
        data: this.get('mapState.currentlySelected.geometry')
      };
    }),
    cdSelectedLayer: {
      id: 'cd-line',
      type: 'line',
      source: 'currentlySelected',
      paint: {
        'line-width': 3,
        'line-color': '#000',
        'line-dasharray': [3, 2],
        'line-opacity': 0.6
      }
    },
    mouseoverLocation: null,
    'tooltip-text': '',
    actions: {
      handleMapLoad(map) {
        map.addControl(new _mapboxGl.default.NavigationControl(), 'top-left');
      },
      handleMouseover(e) {
        const feature = e.target.queryRenderedFeatures(e.point, {
          layers: ['landuse-vector']
        })[0];
        if (feature) {
          const {
            description,
            address
          } = feature.properties;
          const tooltipDescription = description || 'Other';
          e.target.getCanvas().style.cursor = 'pointer';
          this.set('mouseoverLocation', {
            x: e.point.x + 30,
            y: e.point.y
          });
          this.set('tooltip-text', `${address} ${tooltipDescription}`);
        } else {
          e.target.getCanvas().style.cursor = '';
          this.set('mouseoverLocation', null);
        }
      },
      handleMouseleave() {
        this.set('mouseoverLocation', null);
      }
    },
    mapState: (0, _service.inject)()
  });
  _exports.default = _default;
});