define("labs-community-portal/components/tether-popover-on-element", ["exports", "ember-tooltips/components/tether-popover-on-element"], function (_exports, _tetherPopoverOnElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _tetherPopoverOnElement.default;
    }
  });
});