define("labs-community-portal/components/mapbox-gl-layer", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // extends ember-mapbox-gl's layer component to have an API that is more like mapbox.addLayer()
  // eslint-disable-line
  var _default = _component.default.extend({
    map: null,
    layer: {},
    before: '',
    init() {
      this._super(...arguments); // eslint-disable-line

      const {
        layer,
        before
      } = (0, _object.getProperties)(this, 'layer', 'before');
      this.map.addLayer(layer, before);
    },
    willDestroy() {
      this._super(...arguments); // eslint-disable-line

      this.map.removeLayer(this.layer.id);
    }
  });
  _exports.default = _default;
});