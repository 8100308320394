define("labs-community-portal/models/district", ["exports", "@ember/object", "ember-data", "@turf/bbox", "@turf/centroid", "numeral", "labs-community-portal/utils/nabesCrosswalk"], function (_exports, _object, _emberData, _bbox, _centroid, _numeral, _nabesCrosswalk) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line
  // eslint-disable-line
  // eslint-disable-line

  const acronymCrosswalk = {
    Bronx: 'X',
    Brooklyn: 'K',
    Manhattan: 'M',
    Queens: 'Q',
    'Staten Island': 'R'
  };
  const resourcesAcronymCrosswalk = {
    Bronx: 'BX',
    Brooklyn: 'BK',
    Manhattan: 'MN',
    Queens: 'QN',
    'Staten Island': 'SI'
  };
  var _default = _emberData.default.Model.extend({
    borocd: _emberData.default.attr('number'),
    boro: _emberData.default.attr('string'),
    borocdAcronym: (0, _object.computed)('boro', function () {
      const acronym = resourcesAcronymCrosswalk[this.get('boro')];
      const cd = (0, _numeral.default)(this.get('cd')).format('00');
      return `${acronym}${cd}`;
    }),
    zapAcronym: (0, _object.computed)('boro', function () {
      const acronym = acronymCrosswalk[this.get('boro')];
      const cd = (0, _numeral.default)(this.get('cd')).format('00');
      return `${acronym}${cd}`;
    }),
    borocdAcronymLowerCase: (0, _object.computed)('boro', function () {
      const acronym = acronymCrosswalk[this.get('boro')].toLowerCase();
      const cd = (0, _numeral.default)(this.get('cd')).format('00');
      return `${acronym}${cd}`;
    }),
    resourcesBoroCdAcronymLowerCase: (0, _object.computed)('boro', function () {
      const acronym = resourcesAcronymCrosswalk[this.get('boro')].toLowerCase();
      const cd = (0, _numeral.default)(this.get('cd')).format('00');
      return `${acronym}${cd}`;
    }),
    boroAcronym: (0, _object.computed)('boro', function () {
      const acronym = acronymCrosswalk[this.get('boro')];
      return `${acronym}`;
    }),
    boroAcronymLowerCase: (0, _object.computed)('boro', function () {
      const acronym = resourcesAcronymCrosswalk[this.get('boro')].toLowerCase();
      return `${acronym}`;
    }),
    healthProfileLink: (0, _object.computed)('boro', function () {
      const boroAcronymLowerCase = this.get('boroAcronymLowerCase');
      let cd = this.get('cd');
      if (boroAcronymLowerCase === 'si' || boroAcronymLowerCase === 'qn') {
        cd = (0, _numeral.default)(cd).format('00');
      }
      return `https://www1.nyc.gov/assets/doh/downloads/pdf/data/2015chp-${boroAcronymLowerCase}${cd}.pdf`;
    }),
    cd: _emberData.default.attr('string'),
    geometry: _emberData.default.attr(),
    bounds: (0, _object.computed)('geometry', function () {
      const geometry = this.get('geometry');
      return (0, _bbox.default)(geometry);
    }),
    centroid: (0, _object.computed)('geometry', function () {
      const geometry = this.get('geometry');
      return (0, _centroid.default)(geometry).geometry.coordinates;
    }),
    neighborhoods: (0, _object.computed)('borocd', function () {
      const borocd = this.get('borocd');
      return _nabesCrosswalk.default[borocd].join(', ');
    }),
    dataprofile: {},
    name: (0, _object.computed)('boro', 'cd', 'neighborhoods', function () {
      const {
        boro,
        cd,
        neighborhoods
      } = this.getProperties('boro', 'cd', 'neighborhoods');
      return `${boro} ${cd} - ${neighborhoods}`;
    })
  });
  _exports.default = _default;
});