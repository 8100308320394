define("labs-community-portal/components/key-indicators", ["exports", "@ember/object", "@ember/array", "@ember/component", "labs-community-portal/utils/carto"], function (_exports, _object, _array, _component, _carto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    shouldRender: false,
    borocd: '',
    indicators: ['borocd'],
    coalescedIndicators: (0, _array.A)([]),
    sql: (0, _object.computed)('indicators.[]', function () {
      const indicators = this.get('indicators').join(',');
      return `SELECT ${indicators},
      CASE
        WHEN LEFT(borocd::text, 1) = '1' THEN 'Manhattan ' || borocd %25 100
        WHEN LEFT(borocd::text, 1) = '2' THEN 'Bronx ' || borocd %25 100
        WHEN LEFT(borocd::text, 1) = '3' THEN 'Brooklyn ' || borocd %25 100
        WHEN LEFT(borocd::text, 1) = '4' THEN 'Queens ' || borocd %25 100
        WHEN LEFT(borocd::text, 1) = '5' THEN 'Staten Island ' || borocd %25 100
      END as boro_district,
      borocd
      FROM community_district_profiles_v202402`;
    }),
    comparisonData: (0, _object.computed)('sql', function () {
      const sql = this.get('sql');
      return _carto.default.SQL(sql, 'json');
    })
  });
  _exports.default = _default;
});