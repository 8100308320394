define("labs-community-portal/templates/components/navigation-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vjCd/Ujy",
    "block": "[[[8,[39,0],null,[[\"@options\",\"@selected\",\"@searchEnabled\",\"@searchField\",\"@placeholder\",\"@onChange\",\"@matcher\",\"@onInput\"],[[99,1,[\"@options\"]],[99,2,[\"@selected\"]],true,\"name\",[99,3,[\"@placeholder\"]],[99,4,[\"@onChange\"]],[99,5,[\"@matcher\"]],[28,[37,6],[[30,0],[33,7]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"strong\"],[14,0,\"district\"],[12],[1,[30,1,[\"boro\"]]],[1,\" \"],[1,[30,1,[\"cd\"]]],[13],[1,\" \"],[10,\"small\"],[14,0,\"neighborhoods\"],[12],[1,[30,1,[\"neighborhoods\"]]],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"district\"],false,[\"power-select\",\"options\",\"selected\",\"placeholder\",\"onchange\",\"matcher\",\"action\",\"handleSearch\"]]",
    "moduleName": "labs-community-portal/templates/components/navigation-dropdown.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});