define("labs-community-portal/templates/components/key-indicator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qhhQfuEf",
    "block": "[[[10,\"h4\"],[14,0,\"subsection-header\"],[12],[10,\"strong\"],[12],[1,[34,0]],[41,[33,2],[[[1,[28,[35,3],null,[[\"tip\"],[[33,2]]]]]],[]],null],[13],[13],[1,\"\\n\"],[10,0],[14,0,\"callout\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"indicator-grid\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"indicator-cell--stat text-center\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"stat\"],[12],[1,[28,[35,4],[[33,5],[33,6]],null]],[1,[34,7]],[13],[1,\"\\n      \"],[10,\"small\"],[14,0,\"stat-footer\"],[12],[18,1,null],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"indicator-cell--borocity text-right large-text-left xlarge-text-right\"],[12],[1,\"\\n      \"],[41,[33,9],[[[10,1],[14,0,\"boro-stat label\"],[12],[1,[34,10]],[1,\": \"],[10,\"strong\"],[12],[1,[28,[35,4],[[33,9],[33,6]],null]],[1,[34,7]],[13],[13]],[]],null],[1,\"\\n      \"],[41,[33,11],[[[10,1],[14,0,\"city-stat label\"],[12],[1,\"NYC: \"],[10,\"strong\"],[12],[1,[28,[35,4],[[33,11],[33,6]],null]],[1,[34,7]],[13],[13]],[]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"indicator-cell--chart\"],[12],[1,\"\\n      \"],[1,[28,[35,12],null,[[\"data\",\"ranked\",\"column\",\"moe\",\"unit\",\"numeral_format\"],[[33,13],[33,14],[33,15],[33,16],[33,7],[33,6]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"name\",\"if\",\"tip\",\"info-tooltip\",\"numeral-format\",\"cd_stat\",\"numeral_format\",\"unit\",\"yield\",\"boro_stat\",\"boro\",\"city_stat\",\"ranking-chart\",\"sortedData\",\"borocd\",\"column\",\"moe\"]]",
    "moduleName": "labs-community-portal/templates/components/key-indicator.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});