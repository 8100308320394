define("labs-community-portal/components/building-type-chart", ["exports", "@ember/object", "@ember/component", "ember-resize/mixins/resize-aware", "labs-community-portal/utils/carto", "labs-community-portal/utils/building-type-lookup"], function (_exports, _object, _component, _resizeAware, _carto, _buildingTypeLookup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line

  const BuildingTypeChart = _component.default.extend(_resizeAware.default, {
    classNameBindings: ['loading'],
    classNames: ['relative'],
    resizeWidthSensitive: true,
    resizeHeightSensitive: true,
    loading: false,
    borocd: '',
    sql: (0, _object.computed)('borocd', 'type', 'mode', function () {
      const modePrefix = this.get('mode') === '100-yr' ? '1' : '5';
      const typeAbbrev = this.get('type') === 'buildings' ? 'bt' : 'du';
      return `
      SELECT
        ${typeAbbrev}${modePrefix}01 AS "1",
        ${typeAbbrev}${modePrefix}02 AS "2",
        ${typeAbbrev}${modePrefix}03 AS "3",
        ${typeAbbrev}${modePrefix}04 AS "4",
        ${typeAbbrev}${modePrefix}05 AS "5",
        ${typeAbbrev}${modePrefix}06 AS "6",
        ${typeAbbrev}${modePrefix}07 AS "7",
        ${typeAbbrev}${modePrefix}08 AS "8",
        ${typeAbbrev}${modePrefix}09 AS "9",
        ${typeAbbrev}${modePrefix}10 AS "10",
        ${typeAbbrev}${modePrefix}11 AS "11"
      FROM planninglabs.cd_floodplains
      WHERE borocd = ${this.get('borocd')}
    `;
    }),
    data: (0, _object.computed)('sql', 'borocd', function () {
      const sql = this.get('sql');
      return _carto.default.SQL(sql).then(rawData => {
        // eslint-disable-line
        const data = rawData[0];
        const total = Object.keys(data).map(key => data[key]).reduce((acc, curr) => acc + curr);
        return Object.keys(data).map(key => {
          const group = parseInt(key, 10);
          const value = data[key];
          const value_pct = value / total; // eslint-disable-line
          return {
            group: (0, _buildingTypeLookup.default)(group).description,
            color: (0, _buildingTypeLookup.default)(group).color,
            value,
            value_pct
          };
        });
      });
    })
  });
  var _default = BuildingTypeChart;
  _exports.default = _default;
});