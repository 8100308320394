define("labs-community-portal/templates/components/needs-statements", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ne0fD2VW",
    "block": "[[[10,0],[14,0,\"needs-statements\"],[12],[1,\"\\n  \"],[10,\"h6\"],[12],[1,\"Needs Statement Archive:\"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"no-bullet\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[28,[37,2],[[33,3]],null]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[14,0,\"list-item-padded\"],[12],[10,3],[15,6,[29,[[36,4],\"/\",[30,1,[\"path\"]]]]],[12],[8,[39,5],null,[[\"@icon\"],[\"file-pdf\"]],null],[1,\" \"],[10,\"strong\"],[12],[1,[30,1,[\"name\"]]],[13],[13],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[51,[33,7]],[[[1,\"    \"],[10,\"button\"],[14,0,\"button small hollow no-margin\"],[15,\"onclick\",[28,[37,8],[[30,0],\"toggleList\"],null]],[12],[10,\"strong\"],[12],[1,\"Show All Needs Statements\"],[13],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,3],[14,6,\"https://github.com/NYCPlanning/labs-cd-needs-statements/raw/master/Community_District_Needs_Glossary.pdf\"],[14,\"target\",\"_blank\"],[12],[1,\"\\n      \"],[10,\"button\"],[14,0,\"button small hollow no-margin\"],[12],[8,[39,5],null,[[\"@icon\"],[\"file-pdf\"]],null],[1,\" \"],[10,\"strong\"],[12],[1,\"Glossary\"],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null],[1,\"\\n\"]],[\"year\",\"&default\"],false,[\"each\",\"-track-array\",\"await\",\"truncatedYears\",\"downloadPath\",\"fa-icon\",\"unless\",\"showAll\",\"action\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/needs-statements.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});