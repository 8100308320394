define("labs-community-portal/routes/profile", ["exports", "@ember/service", "@ember/routing/route", "ember-leaflet", "labs-community-portal/mixins/scroll-to-top", "labs-community-portal/utils/carto"], function (_exports, _service, _route, _emberLeaflet, _scrollToTop, _carto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  // eslint-disable-line

  function buildBorocd(boro, cd) {
    let borocode;
    switch (boro) {
      case 'manhattan':
        borocode = 100;
        break;
      case 'bronx':
        borocode = 200;
        break;
      case 'brooklyn':
        borocode = 300;
        break;
      case 'queens':
        borocode = 400;
        break;
      case 'staten-island':
        borocode = 500;
        break;
      default:
    }
    return borocode + parseInt(cd, 10);
  }
  var _default = _route.default.extend(_scrollToTop.default, {
    queryParams: {
      section: {
        refresh: true
      }
    },
    mapState: (0, _service.inject)(),
    model(params) {
      const {
        boro,
        cd
      } = params;
      const borocd = buildBorocd(boro, cd);
      const sql = `SELECT * FROM community_district_profiles_v202402 WHERE borocd=${borocd}`;
      const selectedDistrict = this.modelFor('application').findBy('borocd', borocd);
      return _carto.default.SQL(sql, 'json').then(json => {
        selectedDistrict.set('dataprofile', json[0]);
        return selectedDistrict;
      });
    },
    afterModel(district) {
      const mapState = this.get('mapState');
      mapState.setProperties({
        bounds: district.get('bounds'),
        centroid: district.get('centroid')
      });
    },
    actions: {
      error() {
        this.transitionTo('/not-found');
      },
      didTransition() {}
    }
  });
  _exports.default = _default;
});