define("labs-community-portal/services/map-state", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  const DEFAULT_BOUNDS = [-74.077644, 40.690913, -73.832692, 40.856654];
  var _default = _service.default.extend({
    bounds: DEFAULT_BOUNDS,
    currentlySelected: null,
    mapInstance: null,
    currentAddress: null
  });
  _exports.default = _default;
});