define("labs-community-portal/controllers/profile", ["exports", "@ember/runloop", "@ember/object", "@ember/service", "@ember/controller", "d3-array"], function (_exports, _runloop, _object, _service, _controller, _d3Array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line
  // eslint-disable-line
  var _default = _controller.default.extend({
    mapState: (0, _service.inject)(),
    scroller: (0, _service.inject)(),
    metrics: (0, _service.inject)(),
    queryParams: ['section'],
    noSON: (0, _object.computed)('model', function () {
      const d = this.get('model.dataprofile');
      return d.son_issue_1.length === 0 && d.son_issue_2.length === 0 && d.son_issue_3.length === 0;
    }),
    racialProfile: (0, _object.computed)('model', function () {
      const d = this.get('d');
      const profile = [{
        value_pct: d.pct_white_nh / 100,
        value: d.pct_white_nh,
        group: 'White (Non-Hispanic)'
      }, {
        value_pct: d.pct_black_nh / 100,
        value: d.pct_black_nh,
        group: 'Black (Non-Hispanic)'
      }, {
        value_pct: d.pct_asian_nh / 100,
        value: d.pct_asian_nh,
        group: 'Asian (Non-Hispanic)'
      }, {
        value_pct: d.pct_other_nh / 100,
        value: d.pct_other_nh,
        group: 'Other Race (Non-Hispanic)'
      }, {
        value_pct: d.pct_hispanic / 100,
        value: d.pct_hispanic,
        group: 'Hispanic (of any race)'
      }];
      return profile;
    }),
    inFloodplain: (0, _object.computed)('model', function () {
      const {
        fp_100_bldg,
        fp_500_bldg
      } = this.get('model.dataprofile'); // eslint-disable-line
      return fp_100_bldg !== 0 || fp_500_bldg !== 0; // eslint-disable-line
    }),

    popDensity: (0, _object.computed)('model', function () {
      const d = this.get('d');
      const {
        pop_2010,
        area_sqmi
      } = d; // eslint-disable-line

      return pop_2010 / area_sqmi; // eslint-disable-line
    }),

    agePopDist: (0, _object.computed)('model', function () {
      const d = this.get('d');
      const groups = (0, _d3Array.range)(4, 85, 5).reduce((newArr, curr, i, arr) => {
        if (i + 1 <= arr.length - 1) newArr.push(`${curr + 1}_${arr[i + 1]}`);
        return newArr;
      }, []);
      groups.push('85_over');
      groups.unshift('under_5');
      return groups.map(group => ({
        group,
        male: d[`male_${group}`],
        female: d[`female_${group}`]
      }));
    }),
    columns: ['poverty_rate', 'unemployment', 'crime_count', 'mean_commute', 'pct_hh_rent_burd', 'pct_clean_strts', 'pct_bach_deg', 'pct_served_parks', 'moe_poverty_rate', 'moe_bach_deg', 'moe_unemployment', 'moe_mean_commute', 'moe_hh_rent_burd', 'lep_rate', 'moe_lep_rate', 'under18_rate', 'over65_rate', 'moe_under18_rate', 'moe_over65_rate'],
    dataprofileColumns: (0, _object.computed)('model', function () {
      return Object.keys(this.get('model.dataprofile'));
    }),
    dataprofileDownload: (0, _object.computed)('dataprofileColumns', function () {
      const columns = this.get('dataprofileColumns').join(',');
      const model = this.get('model');
      return `https://planninglabs.carto.com/api/v2/sql?format=csv&q=SELECT ${columns} FROM community_district_profiles_v202402&filename=${model.get('boro')}-${model.get('cd')}-indicators.csv`;
    }),
    section: '',
    d: (0, _object.computed)('model', function () {
      return this.get('model.dataprofile');
    }),
    actions: {
      handleAfterScroll(target) {
        const scroller = this.get('scroller');
        this.set('section', target);
        (0, _runloop.next)(this, () => {
          scroller.scrollVertical(`#${target}`, {
            // offset: -210,
          });
        });
      },
      trackAnchorLink(anchor) {
        const metrics = this.get('metrics');
        metrics.trackEvent({
          eventCategory: 'Anchor Link',
          eventAction: 'Click',
          eventLabel: `${anchor}`,
          category: 'Anchor Link',
          action: 'Click',
          name: `${anchor}`
        });
      }
    }
  });
  _exports.default = _default;
});