define("labs-community-portal/templates/components/zoning-map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EeBPkMMr",
    "block": "[[[6,[39,0],null,[[\"id\",\"initOptions\",\"mapLoaded\"],[\"zoning-map\",[33,1],[28,[37,2],[[30,0],\"handleMapLoad\"],null]]],[[\"default\"],[[[[44,[[28,[37,4],[[33,5]],null]],[[[41,[30,2],[[[6,[30,1,[\"source\"]],null,[[\"sourceId\",\"source\"],[\"zoning\",[30,2]]],[[\"default\"],[[[[1,\"        \"],[1,[28,[30,3,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,7],\"place_other\"]]]],[1,\"\\n        \"],[1,[28,[30,3,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,8],\"place_other\"]]]],[1,\"\\n\"]],[3]]]]],[1,\"\\n\"],[41,[33,9,[\"currentlySelected\"]],[[[6,[30,1,[\"source\"]],null,[[\"sourceId\",\"source\"],[\"currentlySelected\",[33,10]]],[[\"default\"],[[[[1,\"          \"],[1,[28,[30,4,[\"layer\"]],null,[[\"layer\",\"before\"],[[33,11],\"place_other\"]]]],[1,\"\\n\"]],[4]]]]]],[]],null]],[]],null]],[2]]],[1,\"\\n    \"],[1,[28,[30,1,[\"call\"]],[\"fitBounds\",[33,9,[\"bounds\"]],[33,12]],null]],[1,\"\\n    \"],[1,[28,[30,1,[\"on\"]],[\"mousemove\",[28,[37,2],[[30,0],\"handleMouseover\"],null]],null]],[1,\"\\n    \"],[1,[28,[30,1,[\"on\"]],[\"mouseout\",[28,[37,2],[[30,0],\"handleMouseleave\"],null]],null]],[1,\"\\n\\n    \"],[1,[28,[35,13],null,[[\"mouse\",\"text\"],[[33,14],[33,15]]]]],[1,\"\\n\"]],[1]]]]],[18,5,null],[1,\"\\n\"]],[\"map\",\"vSource\",\"source\",\"source\",\"&default\"],false,[\"mapbox-gl\",\"initOptions\",\"action\",\"let\",\"await\",\"vectorSource\",\"if\",\"pointsLayer\",\"zoningLabelsLayer\",\"mapState\",\"cdSelectedSource\",\"cdSelectedLayer\",\"fitBoundsOptions\",\"hover-tooltip\",\"mouseoverLocation\",\"tooltip-text\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/zoning-map.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});