define("labs-community-portal/components/subgrade-chart", ["exports", "@ember/object", "@ember/component", "ember-resize/mixins/resize-aware", "labs-community-portal/utils/carto"], function (_exports, _object, _component, _resizeAware, _carto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  const LandUseChart = _component.default.extend(_resizeAware.default, {
    classNames: ['relative'],
    borocd: '',
    sql: (0, _object.computed)('borocd', 'mode', function () {
      const borocd = this.get('borocd');
      const modePrefix = this.get('mode') === '100-yr' ? '1' : '5';
      return `
      SELECT
        bm${modePrefix}bg AS "Full basement below grade",
        bm${modePrefix}ag AS "Full basement above grade",
        bm${modePrefix}un AS "Unknown"
      FROM planninglabs.cd_floodplains
      WHERE borocd = ${borocd}
    `;
    }),
    data: (0, _object.computed)('sql', 'borocd', async function () {
      const sql = this.get('sql');
      return _carto.default.SQL(sql, 'json').then(rawData => {
        const data = rawData[0];
        const total = Object.keys(data).map(key => data[key]).reduce((acc, curr) => acc + curr);
        return Object.keys(data).map(key => {
          const group = key;
          const value = data[key] || 0;
          const value_pct = value / total; // eslint-disable-line
          return {
            group,
            value,
            value_pct
          };
        });
      });
    })
  });
  var _default = LandUseChart;
  _exports.default = _default;
});