define("labs-community-portal/components/site-message", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    // @argument
    warning: true,
    // @argument
    open: true,
    // @argument
    dismissible: true,
    actions: {
      handleSiteMessageToggle() {
        this.set('open', !this.get('open'));
      }
    }
  });
  _exports.default = _default;
});