define("labs-community-portal/templates/components/info-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cLCQCtQm",
    "block": "[[[10,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\"],[\"info-circle\"]],null],[1,\"\\n\"],[6,[39,1],null,[[\"duration\",\"event\"],[50000,\"hover\"]],[[\"default\"],[[[[1,\"    \"],[1,[34,2]],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"fa-icon\",\"tooltip-on-element\",\"tip\"]]",
    "moduleName": "labs-community-portal/templates/components/info-tooltip.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});