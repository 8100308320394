define("labs-community-portal/templates/components/key-indicators", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HKZNs+sJ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"indicator\"],[[50,\"key-indicator\",0,null,[[\"data\",\"boro\",\"cd\",\"borocd\",\"parent\"],[[33,3],[33,4],[33,5],[33,6],[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"comparisonData\",\"boro\",\"cd\",\"borocd\"]]",
    "moduleName": "labs-community-portal/templates/components/key-indicators.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});