define("labs-community-portal/templates/components/subgrade-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fICiXfCX",
    "block": "[[[1,[28,[35,0],null,[[\"data\",\"height\"],[[33,1],90]]]],[1,\"\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"horizontal-bar\",\"data\",\"yield\"]]",
    "moduleName": "labs-community-portal/templates/components/subgrade-chart.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});