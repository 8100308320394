define("labs-community-portal/components/facilities-section", ["exports", "@ember/service", "@ember/object", "@ember/component", "mapbox-gl", "@mapbox/geo-viewport", "labs-community-portal/utils/carto"], function (_exports, _service, _object, _component, _mapboxGl, _geoViewport, _carto) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line

  // eslint-disable-line
  // eslint-disable-line

  const facdbTable = 'dcp_facilities';
  const colorsArray = [['EDUCATION, CHILD WELFARE, AND YOUTH', '#f7ca00'], ['PARKS, GARDENS, AND HISTORICAL SITES', '#4CAF50'], ['LIBRARIES AND CULTURAL PROGRAMS', '#73E5F4'], ['PUBLIC SAFETY, EMERGENCY SERVICES, AND ADMINISTRATION OF JUSTICE', '#2979FF'], ['HEALTH AND HUMAN SERVICES', '#BA68C8'], ['CORE INFRASTRUCTURE AND TRANSPORTATION', '#8D8EAA'], ['ADMINISTRATION OF GOVERNMENT', '#CBCBD6']];
  const SQL = `
  SELECT the_geom_webmercator, facname, facdomain, uid FROM ${facdbTable};
`;
  var _default = _component.default.extend({
    borocd: '',
    initOptions: (0, _object.computed)('mapState', function () {
      const {
        bounds
      } = this.get('mapState');
      const {
        center,
        zoom
      } = _geoViewport.default.viewport(bounds, [400, 400]);
      return {
        style: 'https://layers-api.planninglabs.nyc/v1/base/style.json',
        center,
        zoom,
        scrollZoom: false
      };
    }),
    fitBoundsOptions: {
      linear: true,
      duration: 0
    },
    vectorSource: (0, _object.computed)('facilitiesTemplate', function () {
      return _carto.default.getVectorTileTemplate([SQL]).then(facilitiesTemplate => ({
        type: 'vector',
        tiles: [facilitiesTemplate]
      }));
    }),
    pointsLayer: {
      id: 'facilities-points-outline',
      type: 'circle',
      source: 'facilities',
      'source-layer': 'layer0',
      paint: {
        'circle-radius': {
          stops: [[10, 3], [15, 7]]
        },
        'circle-color': '#012700',
        'circle-opacity': 0.7
      }
    },
    pointsOutlineLayer: {
      id: 'facilities-points',
      type: 'circle',
      source: 'facilities',
      'source-layer': 'layer0',
      paint: {
        'circle-radius': {
          stops: [[10, 2], [15, 6]]
        },
        'circle-color': {
          property: 'facdomain',
          type: 'categorical',
          stops: colorsArray
        },
        'circle-opacity': 0.7
      }
    },
    sql: (0, _object.computed)('borocd', function sql() {
      const borocd = this.get('borocd');
      const joinSql = `
      SELECT facdomain, count(facdomain)
      FROM ${facdbTable} a
      INNER JOIN community_districts b
      ON ST_Contains(b.the_geom, a.the_geom)
      WHERE b.borocd = '${borocd}'
      GROUP BY facdomain
    `;
      return joinSql;
    }),
    data: (0, _object.computed)('sql', 'borocd', function () {
      const sql = this.get('sql');
      return _carto.default.SQL(sql).then(data => {
        // eslint-disable-line
        return data.map(d => {
          const dWithColor = d;
          [, dWithColor.color] = colorsArray.find(el => el[0] === d.facdomain);
          return dWithColor;
        });
      });
    }),
    cdSelectedSource: (0, _object.computed)('mapState.currentlySelected', function () {
      return {
        type: 'geojson',
        data: this.get('mapState.currentlySelected.geometry')
      };
    }),
    centroid: (0, _object.computed)('mapState.currentlySelected', function () {
      return this.get('mapState.centroid');
    }),
    cdSelectedLayer: {
      id: 'cd-line',
      type: 'line',
      source: 'currentlySelected',
      paint: {
        'line-width': 3,
        'line-color': '#000',
        'line-dasharray': [3, 2],
        'line-opacity': 0.6
      }
    },
    mouseoverLocation: null,
    'tooltip-text': '',
    actions: {
      handleMapLoad(map) {
        map.addControl(new _mapboxGl.default.NavigationControl(), 'top-left');
      },
      handleMouseover(e) {
        const feature = e.target.queryRenderedFeatures(e.point, {
          layers: ['facilities-points']
        })[0];
        if (feature) {
          const {
            facname
          } = feature.properties;
          e.target.getCanvas().style.cursor = 'pointer';
          this.set('mouseoverLocation', {
            x: e.point.x + 30,
            y: e.point.y
          });
          this.set('tooltip-text', `${facname}`);
        } else {
          e.target.getCanvas().style.cursor = '';
          this.set('mouseoverLocation', null);
        }
      },
      handleMouseleave() {
        this.set('mouseoverLocation', null);
      },
      handleClick(e) {
        const feature = e.target.queryRenderedFeatures(e.point, {
          layers: ['facilities-points']
        })[0];
        if (feature) {
          const {
            uid
          } = feature.properties;
          window.open(`https://capitalplanning.nyc.gov/facility/${uid}`, '_blank');
        }
      }
    },
    mapState: (0, _service.inject)()
  });
  _exports.default = _default;
});