define("labs-community-portal/routes/application", ["exports", "@ember/service", "@ember/routing/route", "@turf/bbox", "labs-community-portal/utils/to-geojson", "labs-community-portal/mixins/set-map-bounds"], function (_exports, _service, _route, _bbox, _toGeojson, _setMapBounds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-line
  // eslint-disable-line
  var _default = _route.default.extend(_setMapBounds.default, {
    metrics: (0, _service.inject)(),
    mapState: (0, _service.inject)(),
    model() {
      return this.store.findAll('district');
    },
    setupController(controller, districts) {
      this._super(controller, districts);
      controller.set('geojson', (0, _toGeojson.default)(districts));
    },
    actions: {
      transitionToProfile(district) {
        const {
          boro,
          borocd
        } = district.getProperties('boro', 'borocd');
        const metrics = this.get('metrics');
        metrics.trackEvent({
          eventCategory: 'Navigation Dropdown',
          eventAction: 'Click',
          eventLabel: `${boro} ${borocd % 100}`,
          eventValue: borocd,
          category: 'Navigation Dropdown',
          action: 'Click',
          name: `${boro} ${borocd % 100}`,
          value: borocd
        });
        const modelName = district.get('constructor.modelName');
        const mapState = this.get('mapState');
        if (modelName === 'address') {
          mapState.set('currentAddress', district);
        }
        this.transitionTo('profile', boro.dasherize(), borocd % 100);
      }
    }
  });
  _exports.default = _default;
});